<script lang="ts" setup>
import gsap from 'gsap'
import { Notify } from 'quasar'
import jpointIcon from '~/assets/img/icon/j-points.svg'
import { formatPrice } from '~/utils'

const emit = defineEmits(['gameHandler', 'tabHandler'])

const router = useRouter()

const gameStore = useGameStore()

const tweened = reactive({
  number: Number(gameStore.userPoints) || 0,
})

const isMember = computed(() => gameStore.state.gameUserData?.userId)

watch(isMember, (m) => {
  if (gameStore.state.gameUserData && !m) {
    Notify.create({
      message: `Haven't joined the Holders Club yet? Complete the task and earn 500 JPoints!`,
      position: 'bottom',
      color: 'cyan-8',
      actions: [
        { label: 'Join now', color: 'white', handler: () => {
          router.replace({ query: { activeTab: 'tasks' } })
        } },
        { label: 'Close', color: 'white', handler: () => {} },
      ],
      timeout: 0,
    })
  }
})

watch(() => gameStore.userPoints, (p) => {
  gsap.to(tweened, { duration: 2.5, number: Number(p) || 0 })
})
</script>

<template>
  <event-container v-if="gameStore.state.events.length !== 0" :event="gameStore.state.events[0]" @tab-handler="(t) => emit('tabHandler', t)" />
  <div class="pumpjack-home">
    <div class="home-top">
      <game-user-avatar is-avatar />

      <div class="home-top__username">
        {{ gameStore.state.tgUserData?.user?.username || 'Anonymous' }}
      </div>
      <div class="home-top__points">
        <img :src="jpointIcon" alt="jpoints icon">
        {{ formatPrice(tweened.number, 2, 2) }}
      </div>
    </div>

    <game-view @game-handler="emit('gameHandler')" @tab-handler="(t) => emit('tabHandler', t)" />
  </div>
</template>

<style lang="scss" scoped>
.home-top__avatar {
  position: relative;
  overflow: initial;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   bottom: -46px;
  //   width: 86px;
  //   height: 100px;
  //   background: url(~/assets/img/event/ice-avatar.svg) no-repeat center;
  //   background-size: contain;
  // }
}
</style>
