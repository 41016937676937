<script lang="ts" setup>
import type { PropType } from 'vue'
import { matClose } from '@quasar/extras/material-icons'
import eventIcon from '~/assets/img/event-box.png'
import barrelsIcon from '~/assets/img/game/barrel.png'
import jpointIcon from '~/assets/img/icon/j-points.svg'
// import eventIcon from '~/assets/img/event-box-2.png'
import { COMMUNITY_LINK } from '~/config'
import type { GameEvent } from '~/services'
import { withZero } from '~/utils'

const props = defineProps({
  event: Object as PropType<GameEvent>,
})

defineEmits(['tabHandler'])

const lastEventWatch = useStorage('lastEventWatch', '')

// const { getWinners } = useGameEvent()

// getWinners()

const result = ref('00:00:00')
const dialog = ref(false)
const eventEnd = ref(false)

const eventUpcoming = ref(false)

const eventStartTimeCET = computed(() => props.event?.startTime)
const eventEndTimeCET = computed(() => props.event?.endTime)

let timeRemainingInterval
function getTimeRemaining() {
  if (!eventEndTimeCET.value) {
    return
  }
  const endTime = new Date(eventEndTimeCET.value).getTime()
  const now = Date.now()
  const timeRemaining = endTime - now

  if (timeRemaining <= 0) {
    clearInterval(timeRemainingInterval)
    eventEnd.value = true
    return '00:00:00'
  }

  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24))
  const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000)

  if (days > 0) {
    result.value = `${days}D ${withZero(hours)}:${withZero(minutes)}:${withZero(seconds)}`
  } else {
    result.value = `${withZero(hours)}:${withZero(minutes)}:${withZero(seconds)}`
  }
}

const winners: any = []

function placePrize(place: number) {
  switch (place) {
    case 0: return 500
    case 1: return 300
    case 2: return 200
  }
}

function gallonsToBarrels(gallons: number | string) {
  return Number(Number(gallons) / 42).toFixed(2)
}

// function tabHandler() {
//   emit('tabHandler', 'friends')
//   dialog.value = false
// }

watch(eventEnd, async (end) => {
  if (end) {
    if (new Date(lastEventWatch.value).getTime() > new Date(String(eventEndTimeCET.value)).getTime()) {
      return
    }
    lastEventWatch.value = new Date().toISOString()
    dialog.value = true
  }
})

onMounted(() => {
  const now = new Date().getTime()
  const start = new Date(String(eventStartTimeCET.value)).getTime()
  if (now < start) {
    eventUpcoming.value = true
    return
  }

  if (lastEventWatch.value?.length === 0
    || (new Date(lastEventWatch.value).getTime() < start)) {
    lastEventWatch.value = new Date().toISOString()
    dialog.value = true
  }

  getTimeRemaining()

  timeRemainingInterval = setInterval(() => {
    getTimeRemaining()
  }, 1000)
})

onUnmounted(() => {
  clearInterval(timeRemainingInterval)
})
</script>

<template>
  <div class="event" @click="dialog = true">
    <img :src="eventIcon" alt="event icon" class="event-icon">
    <div class="event-timer">
      <span v-if="!eventUpcoming"> {{ result }}</span>
      <span v-else style="font-size: 12px;"> UPCOMING</span>
    </div>
  </div>

  <q-dialog v-model="dialog" class="event-dialog">
    <q-card>
      <q-card-section class="event-dialog__title">
        <span v-if="!eventEnd">{{ event?.name }} 🎉🎁</span>
        <span v-else>Event END! 🎉🎁</span>
        <q-icon v-close-popup :name="matClose" />
      </q-card-section>
      <q-card-section class="event-dialog__body">
        <strong>{{ event?.description }}</strong>
        <!-- <div class="place-prize">
          <strong>{{ event?.reward?.points }} <img :src="jpointIcon" alt="jpoints icon" class="jp-icon">
            for each invited user</strong>
        </div> -->
        <br>
        <br>
        <span v-if="!eventEnd">Good luck!⭐️😎💰</span>
      </q-card-section>
      <q-card-section v-if="eventEnd" class="event-dialog__body">
        <div v-if="winners.length > 0">
          <strong class="row q-pb-sm">Winners:</strong>
          <div v-for="(winner, idx) in winners" :key="winner.id" class="event-winner">
            <div class="winner-username">
              {{ idx + 1 }}. <span>{{ winner.data.username }}</span>
            </div>-
            <div class="barrels-amount">
              {{ gallonsToBarrels(winner.amount) }}
              <img :src="barrelsIcon" alt="" class="barrels-icon">
            </div>
            + {{ placePrize(idx) }} <img :src="jpointIcon" alt="jpoints icon" class="jp-icon">
          </div>
        </div>
        <div v-else>
          Clculation points...
        </div>
      </q-card-section>
      <q-card-section>
        <!--  v-if="eventUpcoming" -->
        <div class="row q-mb-sm" style="line-height: normal;">
          Keep track of the event start and all the latest updates in our community
        </div>
        <q-btn unelevated class="join-btn" :href="COMMUNITY_LINK">
          community
        </q-btn>
      </q-card-section>
      <!-- <q-card-section v-else>
        <q-btn unelevated class="join-btn" :disable="eventUpcoming" @click="tabHandler">
          Invite friends
        </q-btn>
      </q-card-section> -->
    </q-card>
  </q-dialog>
</template>

<style lang="scss">
.event {
  position: fixed;
  z-index: 22;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &-timer {
    width: max-content;
    height: 38px;
    border-radius: $border-radius;
    border: 3px solid #fff;
    background: $secondary;
    font-size: 15px;
    font-weight: 600;
    font-family: $font-secondary;
    font-variant-numeric: tabular-nums;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
    padding: 0 10px;
    position: relative;
    z-index: 1;
  }

  &-icon {
    width: 74px;
    height: 60px;
    object-fit: contain;
    animation: shake 0.6s;
    animation-iteration-count: 1;
    animation-delay: 3s;
    position: relative;
    z-index: 2;
  }

  &-winner {
    padding: 0 0 5px;
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    font-weight: 500;
    font-variant-numeric: tabular-nums;

    .winner-username {
      max-width: 100px;
      width: 100%;
      display: flex;
    }

    span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .barrels-amount {
      width: 80px;
      display: flex;
      align-items: center;
      margin: 0 0 0 4px;
    }

    .jp-icon {
      margin: -6px 0 0 4px;
    }

    .barrels-icon {
      width: 20px;
    }
  }
}

.event-dialog {
  color: $primary;
  .q-card {
    min-width: 300px;
  }

  &__title {
    font-family: $font-secondary;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    padding: 16px 16px 0;

    .q-icon {
      position: absolute;
      right: 7px;
      top: 10px;
    }
  }

  &__body {
    font-size: 15px;
    line-height: 18px;
    padding: 8px 16px 0;

    a {
      color: $accent;
      font-weight: 600;
      text-decoration: none;
    }
    .jp-icon {
      width: 20px;
      height: 20px;
      margin-bottom: -6px;
      object-fit: contain;
    }

    .place-prize {
      display: flex;
      flex-direction: column;
      gap: 2px;
      padding: 6px 0;

      .jp-icon {
        width: 14px;
        height: 14px;
        margin-bottom: -2px;
        object-fit: contain;
      }
    }
  }

  .join-btn {
    background-color: $accent;
    color: #fff !important;
    border-radius: $border-radius;
    width: 100%;
    font-size: 20px;
    font-family: $font-secondary;
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  10% {
    transform: rotate(-2deg);
  }

  30% {
    transform: rotate(5deg);
  }

  40% {
    transform: rotate(-5deg);
  }

  50% {
    transform: rotate(5deg);
  }

  60% {
    transform: rotate(-5deg);
  }

  80% {
    transform: rotate(2deg);
  }

  90% {
    transform: rotate(-2deg);
  }
}
</style>
